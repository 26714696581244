import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { ReactNodeish } from '@/utils/types';
import { showModal } from '@/actions/ModalActionCreators';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import ExternalLink from '@/components/shared/ExternalLink';
import Link from '@/router/Link';
import PageSection from '@/components/shared/layout/PageSection';
import S2Dispatcher from '@/utils/S2Dispatcher';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import PropTypes from 'prop-types';
import React from 'react';

export default class AboutUs extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  showFeedbackModal = (): void => {
    trackAnalyticsEvent(ClickEvent.create(EventTarget.Feedback.SHOW));
    this.context.dispatcher.dispatch(showModal({ id: ModalId.FEEDBACK }));
  };

  trackFAQClick = (): void => {
    trackAnalyticsEvent(ClickEvent.create(EventTarget.FAQ));
  };

  render(): ReactNodeish {
    return (
      <PageSection className="about-us-section">
        <div className="container about-us-container">
          <div className="about-us__links-about-s2">
            <h2>{getString(_ => _.aboutUs.label)}</h2>
            <p>{getString(_ => _.aboutUs.about)}</p>
            <Link
              className="flex-row about-link"
              to="ABOUT_ROOT"
              aria-label={getString(_ => _.aboutUs.learnMore)}>
              <>{getString(_ => _.aboutUs.learnMore)}</>
            </Link>
          </div>

          <div className="about-us__link-group">
            {/* Company */}
            <div className="about-us__link-list">
              <h3>{getString(_ => _.aboutUs.company.label)}</h3>
              <Link
                className="flex-row about-link"
                to="ABOUT_ROOT"
                aria-label={getString(_ => _.aboutUs.company.about)}>
                <>{getString(_ => _.aboutUs.company.about)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="ABOUT_PAGE"
                params={{ page: 'publishers' }}
                aria-label={getString(_ => _.aboutUs.company.publishers)}>
                <>{getString(_ => _.aboutUs.company.publishers)}</>
              </Link>
              <ExternalLink
                className="flex-row about-link"
                aria-label={getString(_ => _.aboutUs.company.blog)}
                allowReferrer
                href="https://medium.com/ai2-blog/semantic-scholar/home">
                {getString(_ => _.aboutUs.company.blog)}
              </ExternalLink>
              <ExternalLink
                className="flex-row about-link"
                aria-label={getString(_ => _.aboutUs.company.careers)}
                allowReferrer
                href="https://allenai.org/careers?team=semantic+scholar#current-openings">
                {getString(_ => _.aboutUs.company.careers)}
              </ExternalLink>
            </div>

            {/* Product */}
            <div className="about-us__link-list">
              <h3>{getString(_ => _.aboutUs.product.label)}</h3>
              <Link
                className="flex-row about-link"
                to="PRODUCT_ROOT"
                aria-label={getString(_ => _.aboutUs.product.productOverview)}>
                <>{getString(_ => _.aboutUs.product.productOverview)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'semantic-reader' }}
                aria-label={getString(_ => _.aboutUs.product.reader)}>
                <>{getString(_ => _.aboutUs.product.reader)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'scholars-hub' }}
                aria-label={getString(_ => _.aboutUs.product.scholarsHub)}>
                <>{getString(_ => _.aboutUs.product.scholarsHub)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'beta-program' }}
                aria-label={getString(_ => _.aboutUs.product.beta)}>
                <>{getString(_ => _.aboutUs.product.beta)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'release-notes' }}
                aria-label={getString(_ => _.aboutUs.product.releaseNotes)}>
                <>{getString(_ => _.aboutUs.product.releaseNotes)}</>
              </Link>
            </div>

            {/* API */}
            <div className="about-us__link-list">
              <h3>{getString(_ => _.aboutUs.api.label)}</h3>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'api' }}
                aria-label={getString(_ => _.aboutUs.api.overview)}>
                <>{getString(_ => _.aboutUs.api.overview)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'api/tutorial' }}
                aria-label={getString(_ => _.aboutUs.api.tutorials)}>
                <>{getString(_ => _.aboutUs.api.tutorials)}</>
              </Link>
              <ExternalLink
                className="flex-row about-link"
                aria-label={getString(_ => _.aboutUs.api.documentation)}
                allowReferrer
                href="https://api.semanticscholar.org/api-docs/">
                {getString(_ => _.aboutUs.api.documentation)}
              </ExternalLink>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'api/gallery' }}
                aria-label={getString(_ => _.aboutUs.api.gallery)}>
                <>{getString(_ => _.aboutUs.api.gallery)}</>
              </Link>
            </div>

            {/* Research */}
            <div className="about-us__link-list">
              <h3>{getString(_ => _.aboutUs.research.label)}</h3>
              <ExternalLink
                className="flex-row about-link"
                aria-label={getString(_ => _.aboutUs.research.publications)}
                allowReferrer
                href="https://allenai.org/papers?tag=Semantic%20Scholar">
                {getString(_ => _.aboutUs.research.publications)}
              </ExternalLink>
              <ExternalLink
                className="flex-row about-link"
                aria-label={getString(_ => _.aboutUs.research.researchCareers)}
                allowReferrer
                href="https://allenai.org/careers">
                {getString(_ => _.aboutUs.research.researchCareers)}
              </ExternalLink>
              <ExternalLink
                className="flex-row about-link"
                aria-label={getString(_ => _.aboutUs.research.resources)}
                allowReferrer
                href="https://allenai.org/ai-for-science">
                {getString(_ => _.aboutUs.research.resources)}
              </ExternalLink>
            </div>

            {/* Help */}
            <div className="about-us__link-list">
              <h3>{getString(_ => _.aboutUs.help.label)}</h3>
              <Link
                className="flex-row faq-link"
                to="FAQ_ROOT"
                onClick={this.trackFAQClick}
                aria-label={getString(_ => _.appHeader.FAQButtonLabel)}>
                <>{getString(_ => _.appHeader.FAQButtonLabel)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="ABOUT_PAGE"
                params={{ page: 'librarians' }}
                aria-label={getString(_ => _.aboutUs.help.librarians)}>
                <>{getString(_ => _.aboutUs.help.librarians)}</>
              </Link>
              <Link
                className="flex-row about-link"
                to="PRODUCT_PAGE"
                params={{ page: 'tutorials' }}
                aria-label={getString(_ => _.aboutUs.help.tutorials)}>
                <>{getString(_ => _.aboutUs.help.tutorials)}</>
              </Link>
              <button
                className="flex-row feedback-link link-button"
                onClick={this.showFeedbackModal}
                aria-label={getString(_ => _.appHeader.contactUsButtonLabel)}
                data-test-id="footer-feedback-link">
                {getString(_ => _.appHeader.contactUsButtonLabel)}
              </button>
            </div>
          </div>
        </div>
      </PageSection>
    );
  }
}
